// @import-normalize;
@import './modules/theme/styles/colors';
@import './modules/theme/styles/fonts';

html,
body,
#root {
  color: $text-light-primary;
  height: 100%;
  margin: 0;
}

h1 {
  border-bottom: 1px solid #d5e1db;
  @include merriweather;
  font-size: 14px;
  font-style: italic;
  width: 100%;
  text-align: center;
  letter-spacing: 0;
  color: $text-light-primary;
  opacity: 1;
  font-weight: 400;
  line-height: 0.1em;
}

h1 span {
  background: $app-background;
  padding: 0 10px;
}

h2 {
  @include merriweather;
  font-size: 16px;
  line-height: 30px;
}

a {
  text-decoration: none;
}

.App {
  @include barlow;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
}

.dashboard-container {
  margin: 24px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.overflow-ellipsis {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

button {
  background: rgba(255, 255, 255, 0);
  border: none;
}

.auth-no-number {
  font-size: 13px;
  @include barlow;
}

.auth-help-link {
  font-size: 13px;
  @include barlow;
  cursor: pointer;
  font-weight: 700;
  color: $primary-action;
}

.no-border-button {
  border: none;
}
