$grey-blue: #99b5c2;

// Core Palette
// Color identities came from: https://www.htmlcsscolor.com
$white: #ffffff;
$snow: #fcfcfc;
$link-water: #ced1d9;
$raven: #707277;
$midnight-express: #292b33;
$eucalyptus: #27ae60;
$fire-brick: #b42419;
$bay-of-many: #313C65;
$lavender-grey: #BBBECB;
$whisper: #eaeaea;
$blue-lagoon: #005c86;  // Used only in DropDown and RadioButton.scss
$lochmara: #3677a8; //More widely-used than blue-lagoon; good candidate for use as secondary color.

// Global Color Uses
$primary-action: $eucalyptus;
$error-foreground: $fire-brick;
$app-background: $white;
$text-dark-primary: $white; // text used on a dark background
$primary-button-text: $white;
$default-border: $link-water;
$text-light-secondary: $raven; // text used on a light background
$text-light-primary: $midnight-express; // text used on a light background

// Form Element Color Uses
$selected-radio: $bay-of-many;
$selected-checkbox: $bay-of-many;
$input-background: $app-background;
$dropdown-background: $app-background;
