@import '../../../theme/styles/colors';
@import '../../../theme/styles/fonts';

.Button {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: $app-background;
  width: 100% !important;
  height: 48px;
  border-radius: 4px;
  opacity: 1;
  border: 2px solid $primary-action;
  margin-top: 10px;
  text-decoration: none;
  cursor: pointer;
  &:disabled {
    background-color: #e9e9e9;
    cursor: default;
    color: $primary-action;
  }

  &:hover {
    border: 2px solid #007ab2;
  }

  &--primary {
    background-color: $primary-action;
  }

  &--save {
    color: $lochmara;
  }
  svg {
    fill: $primary-action;
    height: 20px;
    width: 20px;
  }
}
.small-btn {
  width: 100px !important;
  height: 40px;
}

.medium-btn {
  width: 70px;
  height: 40px;
}

.default__button-label {
  @include barlow-semibold;
  width: 22px;
  text-align: center;
  z-index: 1;
  color: $primary-action;
  font-size: 18px;
  line-height: 19.2px;

  &--primary {
    color: $primary-button-text;
  }
}

.green-banner-btn {
  color: $primary-button-text;
  // width: 320px;
  width: 100%;
  background: $primary-action;
  border: none;
  height: 48px;
  cursor: pointer;
}

.plus-icon {
  position: relative;
  margin: 0px 5px;
  height: 14px;
  width: 14px;
  top: 2px;
}
