@import '../../theme/styles/colors';
@import '../../theme/styles/fonts';
@import '../../theme/styles/utility';

.records-container {
  @include merriweather;
  font-style: italic;
  align-items: center;

  .records-heading {
    text-align: left;
    font-size: 22px;
    line-height: 33px;
  }
  .records-text {
    text-align: left;
    font-size: 16px;
    line-height: 24px;
  }
}

.records__flex-container {
  @extend .flex-container,
    .flex-column,
    .flex-align-stretch,
    .flex-nowrap,
    .flex-fullsize,
    .flex-justify-space-between;
}
