.spinner-container {
  min-width: 100%;
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  justify-content: space-around;
}

.loading-spinner {
  height: 100px;
  width: 100px;
}

.spinner-container.mini-spinner {
  min-width: 30px;
  margin: 0px;
  padding: 0px;
  .loading-spinner {
    width: 30px;
    height: auto;
  }
}
