@import './colors';
@import './fonts';
@import './utility';

.cta-error {
  @include barlow;
  @extend .font-weight-override-600;
  @extend .font-size-14;
  color: $error-foreground;
  text-align: center;
}

.flex-page-full-height-wrapper {
  @extend .flex-container,
  .flex-column,
  .flex-align-stretch,
  .flex-nowrap,
  .flex-fullsize,
  .flex-justify-space-between;
};
