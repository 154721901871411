@import '../../../theme/styles/colors';
@import '../../../theme/styles/fonts';
@import '../../../theme/styles/utility';

.page-header {
  @include merriweather;
  @extend .font-style-italic;
  @extend .text-align-center;
  color: $text-light-secondary;

  .header-content {
    @extend .font-size-10;
    flex-grow: .5;
  }

  .header-line {
    content: '\a0';
    background-color: $default-border;
    flex-grow: 4;
    height: 1px;
  }
}
