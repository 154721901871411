@import '../../../theme/styles/colors';
@import '../../../theme/styles/fonts';

.radio-item {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  align-items: center;
  align-content: flex-start;
  padding: 10px 0px;
  max-width: fit-content;
}

.radio-label {
  @include barlow-condensed-semibold;
  font-size: 14px;
  text-align: left !important;
  padding: 0px 7px;
  cursor: pointer;
  color: $blue-lagoon;
  margin-left: 5px;
}

.form-radio-label {
  @include barlow;
  font-size: 14px;
  text-align: left !important;
  cursor: pointer;
  padding: 0px 7px;
}

input[type='radio'] {
  /* remove standard background appearance */
  appearance: none;
}

/* create custom radiobutton appearance */
.basic-radio {
  outline: none;
  cursor: pointer;
  width: 24px;
  height: 24px;
  padding: 5px;
  /* background-color only for content */
  background-clip: content-box;
  border: 1px solid $grey-blue;
  border-radius: 50%;
}

/* appearance for checked radiobutton */
.basic-radio:checked {
  background-color: $lochmara;
}
