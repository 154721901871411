@import '../../../theme/styles/colors';
@import '../../../theme/styles/fonts';

.alert-message {
  @include barlow-condensed;
  font-size: 16px;
  min-width: 254px;
  max-width: 300px;
  padding: 15px 10px;
  border-radius: 4px;
  display: grid;
  grid-template-columns: 22px 1fr;
  margin: auto;
  margin-bottom: 20px;
  p {
    margin: 0;
    line-height: 20px;
    font-size: 16px;
  }
}
.info-icon {
  height: 18px;
  width: 18px;
  margin-top: 2px;
}
.red-message {
  color: $error-foreground;
  background-color: rgba(247, 227, 226, 0.5);
  .info-icon {
    fill: $error-foreground;
  }
}

.blue-message {
  color: #336b84;
  background-color: #e5eceb;
  .info-icon {
    fill: #336b84;
  }
}
.green-message {
  color: #25a757;
  background-color: rgba(211, 237, 221, 0.5);
  .info-icon {
    fill: #51b979;
  }
}
