@import '../../../theme/styles/colors';
@import '../../../theme/styles/fonts';

.simple-property-display__container {
  flex-direction: row;
  flex-wrap: nowrap;
  padding: 8px 0;
}

.simple-property-display__item {
  @include barlow;
  display: inline-block;
  width: 50%;
  text-align: left;
  font-size: 14px;
}

.simple-property-display__label {
  color: $text-light-secondary;
}
