@mixin merriweather {
  font-family: 'Merriweather', sans-serif;
  font-weight: 300;
}

@mixin barlow {
  font-family: 'Barlow', sans-serif;
  font-weight: 500;
}

@mixin barlow-semibold {
  font-family: 'Barlow', sans-serif;
  font-weight: 600;
}

@mixin barlow-condensed {
  font-family: 'Barlow Condensed', sans-serif;
  font-weight: 300;
}

@mixin barlow-condensed-semibold {
  font-family: 'Barlow Condensed', sans-serif;
  font-weight: 600;
}

@mixin opensans {
  font-family: 'Open Sans', sans-serif;
}

@mixin opensans-semibold {
  font-family: 'Open Sans', sans-serif;
  font-weight: 600;
}
