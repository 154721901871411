@import '../../../theme/styles/colors';
@import '../../../theme/styles/fonts';

$input-border-width: 1px;
$input-height: 48px;

.FormTextInput {
  &__textBoxContainer {
    position: relative;
  }

  &__textBox {
    @include barlow;
    font-weight: 500;
    box-sizing: border-box;
    width: 100%;
    height: 48px;
    background: $input-background 0% 0% no-repeat padding-box;
    border: $input-border-width solid $default-border;
    border-radius: 2px;
    opacity: 1;
    display: block;
    text-align: left;
    letter-spacing: 0.14px;
    color: $text-light-primary;
    font-size: 16px;
    outline: 0;
    text-indent: 12px;

    &--error {
      border-color: $error-foreground !important;
      margin-bottom: 5px !important;

      &:focus {
        border-color: $error-foreground !important;
      }
    }

    &:disabled {
      color: rgba(49, 49, 49, 0.6);
      background: #f0f0f0;
    }
  }

  &__label {
    @include barlow;
    width: 27px;
    height: 17px;
    text-align: left;
    font-size: 16px;
    color: $text-light-secondary;
    letter-spacing: 0.002em;
    opacity: 1;
  }
}

.input-error {
  text-align: left;
  @include barlow-condensed-semibold;
  font-size: 12px;
  letter-spacing: 0;
  color: $error-foreground;
  opacity: 1;
  margin-top: -10px;
  padding-right: 5px;
}

.FormTextInput-container {
  min-height: 96px;
}

.togglePassword {
  @include opensans;
  box-sizing: border-box;
  text-transform: uppercase;
  color: $primary-action;
  font-size: 12px;
  font-weight: 700;
  padding: 0 10px;
  cursor: pointer;
  position: absolute;
  top: $input-border-width;
  right: $input-border-width;
  background-color: $input-background; // Necessary to hide native browser password reveal toggle
  height: $input-height - ($input-border-width * 2);
  display: flex;
  align-items: center;
  border: none;

  &:focus {
    outline: auto;
  }
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type='number'] {
  -moz-appearance: textfield;
}

/* Fix that corrects text-alignment on mobile devices and width in Safari */
@media screen and (max-width: 736px) {
  input[type='date'] {
    min-width: 98% !important;
    box-sizing: border-box;
    -webkit-box-sizing: border-box !important;
    padding-top: 15px !important;
    -webkit-padding-before: 15px !important;
    text-align: left;
  }
}
@media screen and (min-width: 737px) {
  input[type='date'] {
    min-width: 98% !important;
    box-sizing: border-box;
    -webkit-box-sizing: border-box !important;
  }
}
