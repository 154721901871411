.screen-container {

  h2 {
    margin-top: 0;
    font-style: italic;
    font-size: 18px;
  }

  .goToSignUp {
    h2 {
      font-size: 16px;
      margin: 0;
    }
  }
}
