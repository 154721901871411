@import '../../../theme/styles/colors';
@import '../../../theme/styles/fonts';
@import '../../../theme/styles/utility';

.page-footer {
  @include barlow;
  @extend .text-align-center;
  @extend .font-weight-override-100;
  @extend .font-size-10;
  color: $text-light-secondary;
  flex-shrink: 0;
}
