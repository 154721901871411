@import '../theme/styles/fonts';

.authorization-box {
  background-color: #f5fbff;
  border-radius: 8px;
  display: flex;
  padding: 10px;
  align-items: baseline;
}

.consent-text {
  font-style: italic;
  font-size: 18px;
}

.authorization-text {
  @include barlow;
  font-size: 16px;
  line-height: 24px;
  margin: 0 0 0 10px;
}

.consent-screen {
  display: flex;
  flex-direction: column;
  height: 85vh;
  justify-content: space-around;
}
