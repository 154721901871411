@import '../../theme/styles/colors';

.confirm-data-match__heading {
  font-style: italic;
  font-size: 18px;
  margin-bottom: 60px;
}

.confirm-data-match__flex-container {
  display: flex;
  flex-direction: column;
  align-items: stretch;
  flex-grow: 1;
  flex-wrap: nowrap;
}

.confirm-data-match__space-between {
  justify-content: space-between;
}

.confirm-data-match__top-spacer {
  padding-top: 20px;
}
