.verify-code__flex-container {
    h1 {
        text-align: center;
    }

    display: flex;
    flex-direction: column;
    align-items: stretch;
    flex-grow: 1;
    flex-wrap: nowrap;
}
