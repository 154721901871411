@import '../../theme/styles/fonts';
@import '../../theme/styles/colors';
@import '../../theme/styles/utility.scss';

.profile-container {

  .gender-container {
    width: 274px;
    min-height: 96px;

    .select-gender {
      width: inherit;
      display: flex;
      width: 274px;
      justify-content: space-around;
      align-items: center;

      .radio-item {
        margin: 0px;

        .basic-radio {
          border: 1px solid $default-border;
        }
        .basic-radio:checked {
          background-color: $selected-radio;
        }
        .radio-label {
          color: $text-light-secondary;
        }
        .form-radio-label {
          color: $text-light-secondary;
        }
      }
    }
  }

  .postal-code-state-name-row {
    display: flex;
    justify-content: space-between;

    .postal-code {
      width: 50%;
    }
    #state {
      width: 100%;
    }
    .FormSelect__form-group {
      width: 35%;
      margin-top: 2px;

      select {
        background: $input-background 0% 0% no-repeat padding-box;
      }
    }
  }

  .toast-error {
    text-align: left;
    font-size: 12px;
    letter-spacing: 0;
    color: $error-foreground;
    opacity: 1;
    margin-top: -10px;
    padding-right: 5px;
  }
}
