@import '../../theme/styles/colors';
@import '../../theme/styles/fonts';
@import '../../theme/styles/utility';

.auth__subtitle {
  @include opensans;
  font-size: 32px;
  font-weight: 400;
  text-align: center;
  span {
    line-height: 45px;
  }
}

.auth-terms__container {
  background-color: $whisper;
  border-radius: 2px;
  padding: 8px;
}

.auth-terms {
  @include barlow;
  color: $text-light-secondary;
  font-size: 9px;
  font-style: normal;
  font-weight: 400;
  line-height: 10.8px;
  width: 100%;
  margin: 0;

  &-link {
    @include barlow-condensed-semibold;
    color: $text-light-secondary;
    font-size: 11px;
    text-decoration: none;
    cursor: pointer;
  }
}

.auth__text {
  @include merriweather;
  font-size: 18px;
  font-style: italic;
  font-weight: 400;
  margin: 0 auto;
  text-align: center;
  width: 272px;
  height: 50px;

  &--smaller {
    @include merriweather;
    font-size: 14px;
    margin: 5px 5px !important;
    padding: 10px 10px;
  }
}

.auth-help {
  @include barlow-condensed;
  color: $text-light-secondary;
  font-size: 11px;
  width: 272px;
  margin: 0 10px;

  &:hover {
    text-decoration: none;
  }
}

.auth-help {
  text-align: center;
  margin: 20px 0px;
  width: 100%;

  &-link {
    @include opensans;
    color: $primary-action;
    font-size: 14px;
    font-weight: 700;
    text-align: center;
  }
}

.passwordStrength-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  color: $primary-action;
  min-width: 274px;

  span {
    color: initial;
  }
}

.password-strength__bar {
  height: 2px;
  width: 32%;
  border: 1px solid $whisper;

  &--active {
    &:first-of-type {
      box-sizing: initial !important;
      background-color: $primary-action;
    }

    &:nth-of-type(2) {
      box-sizing: initial !important;
      background-color: $primary-action;
    }

    &:nth-of-type(3) {
      box-sizing: initial !important;
      background-color: $primary-action;
    }
  }
}

.password-strength__check {
  border-bottom: 2px solid $link-water;
  border-right: 2px solid $link-water;
  display: inline-block;
  height: 10px;
  left: 5px;
  position: absolute;
  transform: rotate(45deg);
  top: 1px;
  width: 4px;

  &--active {
    border-bottom-color: $white;
    border-right-color: $white;
  }

  &__container {
    border: 1px solid $link-water;
    border-radius: 8px;
    display: inline-block;
    height: 16px;
    position: relative;
    width: 16px;

    &--active {
      background-color: $link-water;
    }
  }
}

.password-strength__text {
  @include barlow-condensed;
  flex-grow: 2;
  font-size: 12px;
  margin-left: 2px;
}

.password-strength__indicator {
  align-items: center;
  justify-content: space-between;
  display: flex;
  width: 33%;
}

.sign-up__heading {
  @include merriweather;
  font-style: italic;
  font-size: 14px;
  font-weight: 300;
  margin: 20px 0 0 0;
}
