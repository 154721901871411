@import '../../../theme/styles/colors';
@import '../../../theme/styles/fonts';

.modal-container {
  left: 0;
  top: 0;
  z-index: 9999;
  box-sizing: border-box;
  height: 100vh;
  width: 100vw;
  position: absolute;
  background: rgba(0, 0, 0, 0.3);
}

.modal-dialog {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%) !important;
  background-color: $white;
  width: 90%;
  height: 70%;
  padding: 10px 0;
  border-radius: 12px;
  border: 1px solid $whisper;
}

.modal-content {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.modal-title {
  .modal-title__title {
    @include barlow-semibold;
    font-size: 20px;
    line-height: 24px;
    letter-spacing: 0.002em;
    margin: 0;
  }

  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding: 0 20px 10px 20px;
  color: $text-light-primary;
  border-bottom: 1px solid $whisper;

  .modal-close-span {
    @include barlow-condensed;
    font-size: 32px;
    color: $lavender-grey;
    margin-right: 5px;
  }

  .span__modal-close-X {
    @include barlow-condensed;
    font-size: 48px;
    font-weight: 300;
    font-style: normal;
    line-height: 48px;
    color: $lavender-grey;
  }
}

.modal-body {
  @include merriweather;
  font-size: 18px;
  font-style: italic;
  line-height: 1.5;
  text-align: left;
  padding: 0 25px 15px 25px;
  border-bottom: 1px solid $whisper;
}
.model-footer {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  justify-content: space-around;
  align-items: center;
  align-content: center;
  padding-bottom: 35px;

  .button-container {
    width: 85%;
  }
}
