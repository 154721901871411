@import '../../../theme/styles/_fonts.scss';
@import '../../../theme/styles/utility.scss';

.loading-overlay-container {
  position: absolute;
  top: 0px;
  left: 0px;
  min-width: 100vw;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  justify-content: flex-start;
  align-items: center;
  align-content: stretch;
  z-index: 9999;
  background-color: rgba(255, 255, 255, 0.8);

  .loading-message {
    @include merriweather;
    font-style: italic;
    font-weight: 300;
    min-width: 100vw;
    min-height: 14px;
  }
}

.loading-overlay-spinner {
  height: 300px;
  width: 300px;
}

.loading-overlay-spinner__rotating {
  height: 100px;
  width: 100px;
  margin-top: 25vh;
  animation-direction: normal;
  animation-delay: 0s;
  animation-duration: 3s;
  animation-fill-mode: forwards;
  animation-iteration-count: infinite;
  animation-name: rotateSpinner;
  animation-play-state: running;
  animation-timing-function: linear;
}

@keyframes rotateSpinner {
  0% {transform: rotate(0deg)}
  25% {transform: rotate(90deg)}
  50% {transform: rotate(180deg)}
  75% {transform: rotate(270deg)}
  100% {transform: rotate(360deg)}
 }
