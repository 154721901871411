@import '../../../theme/styles/colors';
@import '../../../theme/styles/fonts';

.FormSelect__form-group {
  min-height: 96px;
}

.FormSelect__form-label {
  @include barlow-condensed-semibold;
  width: 27px;
  height: 17px;
  text-align: left;
  font-size: 14px;
  color: $text-light-secondary;
  opacity: 1;
}

.FormSelect__select {
  @include opensans;
  border-radius: 2px;
  border: 1px solid $grey-blue;
  color: $text-light-primary;
  display: block;
  font-size: 18px;
  height: 48px;
  letter-spacing: 0.14px;
  margin-bottom: 15px;
  opacity: 1;
  padding: 0px 8px;
  text-align: left;
  width: 274px;

  &:disabled {
    color: rgba(49, 49, 49, 0.6);
    background: #f0f0f0;
  }

  &[multiple],
  &[size]:not([size='1']) {
    height: auto;
    padding-right: 8px;
    background-image: none;
  }

  &.is-invalid {
    border-color: $error-foreground;
    margin-bottom: 5px;

    &:focus {
      outline-color: $error-foreground;
    }
  }
}
