@import '../../../theme/styles/fonts';
@import '../../../theme/styles/colors';

$input-border-width: 1px;
$input-height: 48px;

.PhoneInput {
  &__textBoxContainer {
    display: flex;
  }

  &__textBox {
    @include barlow;
    box-sizing: border-box;
    width: 100%;
    height: 48px;
    background: $input-background 0% 0% no-repeat padding-box;
    border: $input-border-width solid $default-border;
    border-radius: 2px;
    opacity: 1;
    display: block;
    text-align: left;
    letter-spacing: 0.14px;
    color: $text-light-primary;
    font-size: 16px;
    outline: 0;
    text-indent: 12px;

    &--error {
      border-color: $error-foreground !important;
      margin-bottom: 5px !important;

      &:focus {
        border-color: $error-foreground !important;
      }
    }

    &:disabled {
      color: rgba(49, 49, 49, 0.6);
      background: $whisper;
    }
  }

  &__label {
    @include barlow;
    width: 27px;
    height: 17px;
    text-align: left;
    font-size: 16px;
    color: $text-light-secondary;
    opacity: 1;
  }
}

.input-error {
  text-align: left;
  @include barlow-condensed-semibold;
  font-size: 12px;
  letter-spacing: 0;
  color: $error-foreground;
  opacity: 1;
  margin-top: -10px;
  padding-right: 5px;
}

.PhoneInput-container {
  min-height: 96px;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type='number'] {
  -moz-appearance: textfield;
}


.dropbtn {
  color: $text-light-primary;
  border: none;
  height: 48px;
  width: 60px;
  background-color: $input-background;
  border: 1px solid $default-border;
}

.dropdown {
  position: relative;
  display: inline-block;
  .st0 {
    fill: $text-light-primary;
  }
}

.dropdown-content {
  display: none;
  position: absolute;
  box-shadow: 0px 8px 16px rgba(0,0,0,0.2);
  z-index: 1;
  width: 60px;
  background-color: $dropdown-background;
  border: 1px solid $default-border;
}

.dropdown-content Button {
  color: $text-light-primary;
  padding: 12px 16px;
  text-decoration: none;
  display: block;
  width: 60px;
  background-color: $dropdown-background;
  border: 1px solid $default-border;
}
.dropdown:hover .dropdown-content {display: block;}
