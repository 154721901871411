@import '../../../theme/styles/_colors.scss';

//hide the native checkbox
input[type='checkbox'] {
  display: none;
}

input[type='checkbox'] + label {
  height: 17px;
  width: 17px;
  display: inline-block;
  padding: 0;
  border: 2px solid $bay-of-many !important;
  border-radius: 4px;
  opacity: 1;
  cursor: pointer;
  margin-left: 8px;
}
input[type='checkbox']:checked + label {
  background: url('../../../theme/assets/CheckboxSnow.svg') 0% 0% no-repeat
    padding-box;
  background-color: $bay-of-many;
  height: 17px;
  width: 17px;
  display: inline-block;
  padding: 0;
  opacity: 1;
  background-position: center;
  cursor: pointer;
}

.checkbox-container {
  display: flex;
  align-items: center;
  margin: 10px 0px;
}

.label-text {
  font-size: 20px;
  line-height: 17px;
  margin-left: 30px;
}
