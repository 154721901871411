/*
 * Unfortunately, all Botco.ai styles use `!important`.
 * Increase selector specificity with double classnames.
 */

.cleanslate.cleanslate {
  .bwc-bot-trigger img {
    box-shadow: none !important;
  }

  .mobile.bwc-bot-trigger {
    bottom: 0 !important;
    right: 0 !important;

    img {
      height: 45px !important;
      width: 45px !important;
    }
  }

  .desktop.bwc-chat.open {
    min-width: 320px;
    width: 350px;
  }
}
